import React, { useState } from 'react';
import { Layout, Tabs, Button, Modal, Form, Input, message, Space, Typography, Tag } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import ResumeUpload from './ResumeUpload';
import ResumeParsing from './ResumeParsing';
import AnalyzeCandidate from './AnalyzeCandidate';
import AdminDashboard from './AdminManagement/AdminDashboard';

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Text, Link } = Typography;

const Dashboard = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isIntroModalVisible, setIsIntroModalVisible] = useState(true); // State for introduction modal
  const [tabKey, setTabKey] = useState('1');

  const hardcodedCredentials = {
    email: 'marshall.jen@cuhk.edu.hk',
    password: 'resumecuhk1234',
  };

  const showLoginModal = () => {
    setIsLoginModalVisible(true);

  };
  const handleLoginCancel = () => {
    setIsLoginModalVisible(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    message.success('Logged out successfully');

  };
  

  const onFinish = (values) => {
    if (
      values.email === hardcodedCredentials.email &&
      values.password === hardcodedCredentials.password
    ) {
      setIsLoginModalVisible(false);
      setIsLoggedIn(true);
      message.success('Logged in successfully');
    } else {
      message.error('Invalid email or password!');
    }
  };

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const handleFileUpload = (file) => {
    setUploadedFile(file);
  };

  return (
    <Layout style={{ minHeight: '100vh', width: '100%', margin: 0 }}>
      <Header style={{ background: '#fff', height: '85px', padding: 0 }}>
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      height: '100%',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
      {/* First Logo - CUHK */}
      <a
        href="https://hk4families.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/cuhk-logo.png"
          alt="HK4Families Logo"
          style={{ height: '60px', marginTop: '30px' }}
        />
      </a>
    

      {/* Second Logo */}
      <a
        href="https://www.bschool.cuhk.edu.hk/centres/centre-for-family-business/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/cuhklogo.png"
          alt="CUHK Logo"
          style={{ height: '70px', marginTop: '30px' }}
        />
      </a>
    </div>
  </div>
</Header>


      <Content style={{ margin: '20px', background: '#fff', padding: '20px' }}>
        {isLoggedIn ? (
          <AdminDashboard />
        ) : (
          <Tabs
            activeKey={tabKey}
            onChange={handleTabChange}
            destroyInactiveTabPane
          >
            <TabPane tab="Family Office Goals" key="1">
              <ResumeUpload
                selectedRole={selectedRole}
                onFileUpload={handleFileUpload}
              />
              {selectedRole && uploadedFile && (
                <ResumeParsing file={uploadedFile} role={selectedRole} />
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  Analyze Candidate{' '}
                  <Tag
                    color="#1890ff"
                    style={{
                      marginLeft: '4px',
                      fontSize: '10px',
                      lineHeight: '14px',
                      height: '16px',
                      padding: '0 4px',
                      borderRadius: '4px',
                      verticalAlign: 'middle',
                      opacity: '0.8',
                    }}
                  >
                    BETA
                  </Tag>
                </span>
              }
              key="2"
            >
              <AnalyzeCandidate />
            </TabPane>
          </Tabs>
        )}
      </Content>

      <Footer
        style={{
          textAlign: 'center',
          backgroundColor: '#4B2E83',
          padding: '16px',
          margin: 0,
        }}
      >
        <Space
          split={<Text style={{ color: '#fff' }}>|</Text>}
          wrap
          style={{
            justifyContent: 'center',
            color: '#fff',
            fontSize: '14px',
          }}
        >
          <Text style={{ color: '#fff' }}>V.2.0.1</Text>

          <Text style={{ color: '#fff' }}>
            Developed by{' '}
            <Link
              href="https://plann.tech"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              PLANN TECH CO., LTD
            </Link>
          </Text>

          <Link
            href="mailto:info@plann.tech"
            style={{ color: '#fff' }}
          >
            <Space>
              <MailOutlined />
              info@plann.tech
            </Space>
          </Link>

          <Text style={{ color: '#fff' }}>
            Under the supervision of Prof. Marshall Jen
          </Text>

          <Link
            onClick={() => setIsPrivacyModalVisible(true)}
            style={{ color: '#fff', textDecoration: 'underline' }}
          >
            Privacy Disclaimer
          </Link>
          {isLoggedIn ? (
            <Button
              type="primary"
              onClick={handleLogout}
              style={{
                backgroundColor: '#ffffff',
                borderColor: '#4B2E83',
                color: '#4B2E83',
              }}
            >
              Log out
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={showLoginModal}
              style={{
                backgroundColor: '#ffffff',
                borderColor: '#4B2E83',
                color: '#4B2E83',
              }}
            >
              Admin
            </Button>
          )}
        </Space>
      </Footer>

     {/* Introduction Modal */}
<Modal
  title="Welcome to CUHK AI Skill Mapping"
  open={isIntroModalVisible}
  onCancel={() => setIsIntroModalVisible(false)}
  footer={[
    <Button
      key="close"
      type="primary"
      onClick={() => setIsIntroModalVisible(false)}
      style={{ backgroundColor: '#4B2E83', borderColor: '#4B2E83' }}
    >
      Get Started
    </Button>,
  ]}
>
  <Typography.Paragraph>
    CUHK AI Skill Mapping is designed to help you match candidate skills with
    predefined roles, analyze gaps, and provide actionable insights for professional
    development.
  </Typography.Paragraph>
  <Typography.Paragraph>
    <strong>How to navigate:</strong>
    <ol>
      <li><strong>Select Family Goals:</strong> Begin by selecting the family goals that are most relevant to your organization.</li>
      <li><strong>Choose Associated Roles:</strong> Once the goals are selected, explore the associated roles that align with these objectives.</li>
      <li><strong>Read Role Explanations:</strong> Review the detailed role descriptions to understand the requirements, skills, and attributes needed for each role.</li>
      <li><strong>Upload Resume:</strong> Navigate to the "Family Office Goals" tab to upload a candidate's resume and analyze the alignment with the selected role.</li>
    </ol>
  </Typography.Paragraph>
  <Typography.Paragraph>
    Follow these steps to maximize the benefits of CUHK AI Skill Mapping and get the most precise insights for skill matching and gap analysis. Start exploring the tabs above!
  </Typography.Paragraph>
</Modal>


      <Modal
        title="Privacy Disclaimer"
        open={isPrivacyModalVisible}
        onCancel={() => setIsPrivacyModalVisible(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={() => setIsPrivacyModalVisible(false)}
            style={{ backgroundColor: '#4B2E83', borderColor: '#4B2E83' }}
          >
            Close
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          We value your privacy. Your uploaded resumes and personal data are{' '}
          <strong>not stored</strong> in our database. All data is processed
          securely and temporarily during analysis and is immediately discarded
          after the process is complete. No personal information is collected,
          retained, or shared.
        </Typography.Paragraph>
      </Modal>

      <Modal
        title="Login"
        visible={isLoginModalVisible}
        onCancel={handleLoginCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{
                width: '100%',
                backgroundColor: '#4B2E83',
                borderColor: '#4B2E83',
              }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
