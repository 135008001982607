import React, { useState, useEffect } from 'react';
import { Upload, message, Typography, Button, Card, Row, Col, Spin, Tag, Progress, Modal, Layout, Divider, Tooltip } from 'antd';
import axios from 'axios';
import { 
  InboxOutlined, UserOutlined, DollarOutlined, TeamOutlined, CalendarOutlined,
  MessageOutlined, GlobalOutlined, HeartOutlined, TrophyOutlined, SafetyOutlined,
  FundOutlined, BankOutlined, RocketOutlined, HistoryOutlined, HomeOutlined,
  ApartmentOutlined, ReadOutlined, ClockCircleOutlined, AimOutlined, InfoCircleOutlined
} from '@ant-design/icons';
import ResumeParsing from './ResumeParsing';
import RecommendationPageContent from './RecommendationPageContent';  

const { Dragger } = Upload;
const { Title, Text, Paragraph } = Typography;
const { Content, Sider } = Layout;

const colorMap = {
  group1: '#20B2AA',  // Lifestyle Concierge, Accounting & Bookkeeping, etc.
  group2: '#00BFFF',  // Public Affairs, Philanthropy (Principals & Policies), etc.
  group3: '#4682B4',  // Investment Policy, Wealth Management, etc.
  group4: '#778899'   // The rest
};

const iconMap = {
  'Lifestyle Concierge': UserOutlined,
  'Accounting & Bookkeeping': DollarOutlined,
  'Social Gathering': TeamOutlined,
  'Family Event Management': CalendarOutlined,
  'Family Communication': MessageOutlined,
  'Public Affairs': GlobalOutlined,
  'Philanthropy (Principals & Policies)': HeartOutlined,
  'Philanthropy (Project & Monitoring)': HeartOutlined,
  'Family Branding & Reputation Management': TrophyOutlined,
  'Crisis Management': SafetyOutlined,
  'Investment Policy': FundOutlined,
  'Wealth Management': BankOutlined,
  'Startup Fund': RocketOutlined,
  'Estate & Family Trust': BankOutlined,
  'Alternative Investment': FundOutlined,
  'Family History': HistoryOutlined,
  'Family Values and Heritage': HomeOutlined,
  'Family Structure & Organisation': ApartmentOutlined,
  'Family Education': ReadOutlined,
  'Succession Planning': ClockCircleOutlined
};


// Function to get the correct background color based on the goal name
const getBackgroundColor = (goalName) => {
  // Group 1: Lifestyle Concierge, Accounting & Bookkeeping, Social Gathering, etc.
  if (['Lifestyle Concierge', 'Accounting & Bookkeeping', 'Social Gathering', 'Family Event Management', 'Family Communication'].includes(goalName)) {
    return colorMap.group1;
  }
  
  // Group 2: Public Affairs, Philanthropy (Principals & Policies), etc.
  if (['Public Affairs', 'Philanthropy (Principals & Policies)', 'Philanthropy (Project & Monitoring)', 'Family Branding & Reputation Management', 'Crisis Management'].includes(goalName)) {
    return colorMap.group2;
  }
  
  // Group 3: Investment Policy, Wealth Management, etc.
  if (['Investment Policy', 'Wealth Management', 'Startup Fund', 'Estate & Family Trust', 'Alternative Investment'].includes(goalName)) {
    return colorMap.group3;
  }

  // Group 4: The rest (default color)
  return colorMap.group4;
};


const ResumeUpload = () => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [resumeFilePath, setResumeFilePath] = useState('');
  const [step, setStep] = useState(1);
  const [roleSkills, setRoleSkills] = useState([]);
  
  const [skillComparison, setSkillComparison] = useState({ 
    matchingSkills: [], 
    similarSkills: [], 
    lackingSkills: [], 
    extraSkills: [], 
    relevantAISkills: [], 
    relevantAITraits: []
  });
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recommendation, setRecommendation] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siderVisible, setSiderVisible] = useState(false);
  const {
    matchingSkills,
    similarSkills,
    lackingSkills,
  } = skillComparison;

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await axios.get(`${API_URL}/goals`);
        console.log('Fetched goals:', response.data);
        setGoals(response.data);
      } catch (error) {
        message.error('Error fetching goals');
      }
    };

    fetchGoals();
  }, [API_URL]);

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
    setSelectedRole(null);
    setSiderVisible(true);
  };


  const handleRoleClick = async (role) => {
    setSelectedRole(role);
    setStep(2);
    console.log('Fetching role details for ID:', role.id);
  
    try {
      // Fetch role details
      const response = await axios.get(`${API_URL}/roles/${role.id}`);
      console.log('Role data received:', response.data);
      
      if (response.data && response.data.role) {
        // Merge the existing role data with the fetched details
        const roleData = response.data.role;
        setSelectedRole({
          ...role,
          ...roleData
        });
  
        // Proceed with AI analysis if we have the required fields
        if (roleData.description || roleData.knowledge || roleData.skill || roleData.attribute) {
          setLoadingAnalysis(true);
          try {
            const aiResponse = await axios.post(`${API_URL}/analyze-role`, {
              description: roleData.description,
              knowledge: roleData.knowledge,
              skill: roleData.skill,
              attribute: roleData.attribute,
            });
  
            console.log('AI Analysis response:', aiResponse.data);
            
            // Set AI analysis data
            const analyzedRoleSkills = aiResponse.data.skills || [];
            setRoleSkills(analyzedRoleSkills);
            setAiAnalysis(aiResponse.data);
          } catch (aiError) {
            console.error('Error in AI analysis:', aiError);
            message.warning('AI analysis could not be completed, but role details are available.');
          } finally {
            setLoadingAnalysis(false);
          }
        } else {
          message.warning('Some role details may be missing for complete analysis.');
          setRoleSkills([]);
          setAiAnalysis(null);
        }
      } else {
        message.error('No role details found.');
      }
    } catch (error) {
      console.error('Error fetching role details:', error);
      message.error('Failed to fetch role details.');
      setLoadingAnalysis(false);
      setRoleSkills([]);
      setAiAnalysis(null);
    }
  };

  
  const handleFileChange = (info) => {
    const { status, originFileObj } = info.file;

    if (status === 'done' || status !== 'uploading') {
      setFile(originFileObj);
      message.success(`${info.file.name} uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} upload failed.`);
    }
  };

  const handleSubmit = async () => {
    if (!file || !selectedRole) {
      message.error('Please upload a file and select a role before proceeding.');
      return;
    }
  
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('roleId', selectedRole.id);
  
    try {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scrolling effect
          });
          const response = await axios.post(`${API_URL}/upload-resume`, formData);
      const { resumeEntities = {}, matchingSkills = [], lackingSkills = [], filePath } = response.data;
  
      setParsedData({
        resumeEntities,
        matchingSkills,
        lackingSkills,
      });
  
      setResumeFilePath(filePath);
      setStep(3);
      message.success('Resume successfully parsed and processed!');
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('File upload failed. Please try again.');
    }
  };
  

  const handleSkillComparison = async () => {
    if (!parsedData || !roleSkills.length) {
      message.error('No parsed data or analyzed role skills found. Parse the resume and analyze the role first.');
      return;
    }
  
    try {

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scrolling effect
          });
      // Perform the comparison between resume skills and the analyzed role skills
      const response = await axios.post(`${API_URL}/compare-skills`, { 
        resumeSkills: parsedData.resumeEntities.skills, // These are the analyzed resume skills
        analyzedRoleSkills: roleSkills, // These are the AI-analyzed role skills
      });
  
      // Handle the comparison result from backend
      const comparisonData = response.data;
  
      // Helper function to remove duplicates
      const removeDuplicates = (arr) => [...new Set(arr.map(skill => skill.toLowerCase().trim()))];
  
      // Filter and remove any non-string elements from arrays
      const ensureStringArray = (arr) => arr.filter(skill => typeof skill === 'string');
  
      // Filter out non-string values before processing
      const filteredMatchingSkills = removeDuplicates(ensureStringArray(comparisonData.matchingSkills));
      const filteredSimilarSkills = removeDuplicates(ensureStringArray(comparisonData.similarSkills.map(skill => skill.jobSkill))); // Assuming 'similarSkills' has 'jobSkill'
      const filteredLackingSkills = removeDuplicates(ensureStringArray(comparisonData.lackingSkills.filter(skill => skill.split(' ').length > 1))); // Only multi-word phrases
      const filteredExtraSkills = removeDuplicates(ensureStringArray(comparisonData.extraSkills));
      const filteredRelevantAISkills = removeDuplicates(ensureStringArray(comparisonData.relevantAISkills));
      const filteredRelevantAITraits = removeDuplicates(ensureStringArray(comparisonData.relevantAITraits));
  
      setSkillComparison({
        matchingSkills: filteredMatchingSkills,
        similarSkills: filteredSimilarSkills,
        lackingSkills: filteredLackingSkills, 
        extraSkills: filteredExtraSkills,
        relevantAISkills: filteredRelevantAISkills,
        relevantAITraits: filteredRelevantAITraits,
      });
  
      message.success('Skills comparison completed successfully!');
      setStep(4);
    } catch (error) {
      console.error('Error comparing skills:', error);
      message.error('Failed to compare skills.');
    }
  };

  const calculateMatchingPercentage = (matchingSkills, similarSkills, totalSkills) => {
    const matchingWeight = 3.0; 
    const similarWeight = 2.0;   
  
    // Calculate total weighted score
    const weightedMatchScore = (matchingSkills.length * matchingWeight) + (similarSkills.length * similarWeight);
  
    // Calculate percentage by dividing the weighted score by the total number of skills
    const matchPercentage = (weightedMatchScore / totalSkills) * 100;
  
    return matchPercentage.toFixed(2);  // Return 2 decimal places
  };
  
  
  const renderAiAnalysis = () => (
    <div>
      <Title level={4}>Job KSAs</Title>
  
      <Card title="Knowledges" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.personalities?.length > 0 ? (
          aiAnalysis.personalities.map((item, index) => (
            <Tag key={index} color="blue">{item}</Tag>
          ))
        ) : (
          <p>No personalities found</p>
        )}
      </Card>
      <Card title="Skills" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.skills?.length > 0 ? (
          aiAnalysis.skills.map((item, index) => (
            <Tag key={index} color="purple">{item}</Tag>
          ))
        ) : (
          <p>No skills found</p>
        )}
      </Card>
  
      <Card title="Abilities" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.abilities?.length > 0 ? (
          aiAnalysis.abilities.map((item, index) => (
            <Tag key={index} color="green">{item}</Tag>
          ))
        ) : (
          <p>No abilities found</p>
        )}
      </Card>
  

    </div>
  );

  
  const formatContent = (content) => {
    // Add null/undefined check
    if (!content) return null;
  
    // Ensure content is a string
    const contentString = String(content);
    
    // Split the content by periods to separate different topics
    const topics = contentString.split(/(?<=\.)\s+/);
    
    return topics.map((topic, index) => {
      if (!topic.trim()) return null;
  
      const colonIndex = topic.indexOf(':');
      if (colonIndex === -1) return topic;
  
      const subject = topic.substring(0, colonIndex).trim();
      const description = topic.substring(colonIndex + 1).trim();
  
      return (
        <Typography.Paragraph 
          key={index} 
          style={{ 
            marginBottom: '24px',
            display: 'block'
          }}
        >
          <Text strong>{subject}:</Text>
          {' '}
          {description}
        </Typography.Paragraph>
      );
    }).filter(Boolean);
  };
  
  const KSAItem = ({ title, content, color }) => {
    // Add prop validation
    if (!title || !content) {
      return null;
    }
  
    return (
      <div className="ksa-section">
        <Title level={4} style={{ 
          color: color || '#000', // Provide default color
          marginBottom: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          textAlign: 'left'
        }}>
          <div style={{ 
            width: '4px', 
            height: '24px', 
            backgroundColor: color || '#000',
            borderRadius: '2px'
          }} />
          {title}
        </Title>
        <div className="ksa-content" style={{ borderLeft: `3px solid ${color || '#000'}` }}>
          {formatContent(content)}
        </div>
      </div>
    );
  };
  
  // In the parent component where KSAItem is used:
  const renderRoleDetails = () => {
    if (!selectedRole) return null;
  
    return (
      <div className="role-details">
        <Card 
          title={<Title level={4}>Role Description</Title>} 
          style={{ marginBottom: '16px' }}
          className="description-card"
        >
          <Paragraph>{selectedRole.description || 'No description available'}</Paragraph>
        </Card>
  
        <Card className="ksa-container">
          {/* Add checks for required props */}
          {selectedRole.knowledge && (
            <KSAItem 
              title="Knowledge" 
              content={selectedRole.knowledge} 
              color="#1890ff" 
            />
          )}
          {selectedRole.skill && (
            <KSAItem 
              title="Skills" 
              content={selectedRole.skill} 
              color="#52c41a" 
            />
          )}
          {selectedRole.attribute && (
            <KSAItem 
              title="Attributes" 
              content={selectedRole.attribute} 
              color="#722ed1" 
            />
          )}
  
          <style jsx>{`
            .ksa-content {
              padding: 24px;
              background: #fafafa;
              border-radius: 4px;
              transition: all 0.3s ease;
              text-align: left;
            }
            .ksa-content > .ant-typography {
              margin-bottom: 24px;
              line-height: 1.8;
            }
            .ksa-content > .ant-typography:last-child {
              margin-bottom: 0;
            }
            .ksa-content .ant-typography strong {
              font-weight: 600;
            }
          `}</style>
        </Card>
      </div>
    );
  };

  const handleGenerateRecommendation = async () => {
    if (!selectedRole || !selectedRole.id) {
      console.error("Role is missing or invalid");
      message.error('Please select a role before generating a recommendation.');
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/generate-recommendation`, {
        roleId: selectedRole.id, // Send the selectedRole.id to the backend
        matchingSkills,
        similarSkills,
        lackingSkills
      });
      setRecommendation(response.data.recommendation);
      setIsModalVisible(true); // Show modal once recommendation is generated
    } catch (error) {
      console.error("Error generating recommendation:", error);
      message.error('Error generating recommendation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  
  const renderContent = () => {
    switch(step) {
      case 1:
        return (
          <>
           <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
  Family Office Goals <Tooltip
    title="This page helps you explore and manage Family Office Goals. Click on a goal to view its details, associated roles, and begin analyzing resumes against the selected role."
  >
    <InfoCircleOutlined
      style={{
        fontSize: '18px',
        color: '#1890ff',
        cursor: 'pointer',
      }}
    />
  </Tooltip>
</Title>
<Paragraph
  style={{
    textAlign: 'center',
    fontSize: '16px',
    color: '#555',
    maxWidth: '800px',
    margin: '0 auto',
  }}
>
  Family Office Goals serve as the cornerstone for building and sustaining long-term
  family wealth, legacy, and governance. These goals guide strategic planning,
  decision-making, and operational alignment within family offices, ensuring a balance
  between financial objectives, philanthropy, and familial values.
</Paragraph>



            <Row gutter={[16, 16]}>
              {goals.map((goal, index) => {
                const IconComponent = iconMap[goal.goal_name] || UserOutlined;
                return (
                  <Col xs={24} sm={12} md={8} lg={6} key={goal.id}>
                    <Card
                      hoverable
                      onClick={() => handleGoalClick(goal)}
                      style={{
                        backgroundColor: getBackgroundColor(goal.goal_name),  // Use the getBackgroundColor function
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '20px',
                        minHeight: '150px',
                        borderRadius: '10px',
                      }}
                      bodyStyle={{ padding: 0 }}
                    >
                      <IconComponent style={{ fontSize: '36px', color: 'white', marginBottom: '10px' }} />
                      <Title level={5} style={{ color: 'white', margin: 0 }}>{goal.goal_name}</Title>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        );
        case 2:
          return selectedRole && (
            <div>

              <Card 
                title={
                  <div style={{ 
                    fontSize: '24px', 
                    fontWeight: 700,
                    paddingBottom: '8px',
                    paddingTop: '8px'
                  }}>
                    {selectedRole.role}
                  </div>
                }
                style={{ marginBottom: '20px' }}
              >
                {/* Role Details Section */}
                {renderRoleDetails()}
                
                {/* AI Analysis Section - Hidden but functional */}
                <div style={{ display: 'none' }}>
                  {loadingAnalysis ? (
                    <Spin tip="Analyzing role..." />
                  ) : (
                    aiAnalysis && renderAiAnalysis()
                  )}
                </div>
              </Card>
        
              <Dragger
                name="file"
                multiple={false}
                accept=".pdf,.doc,.docx"
                customRequest={({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                onChange={handleFileChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag a resume file to upload (PDF Recommended)</p>
              </Dragger>
              <Button 
                type="primary" 
                onClick={handleSubmit} 
                style={{ marginTop: '20px', width: '100%' }}
              >
                Submit Resume for Skill Analysis
              </Button>
            </div>
          );
      case 3:
        return selectedRole && parsedData && (
          <div>
            <Title level={3}>Resume Analysis</Title>
            <div style={{ padding: '20px' }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Resume Preview" style={{ marginBottom: '20px' }}>
                    {resumeFilePath ? (
                      <iframe
                        src={`${API_URL}${resumeFilePath}`}
                        style={{ width: '100%', height: '500px' }}
                        title="Resume Preview"
                      ></iframe>
                    ) : (
                      <p>No file available</p>
                    )}
                  </Card>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Card>
                    <ResumeParsing parsedData={parsedData} roleSkills={roleSkills} aiAnalysis={aiAnalysis} />
                  </Card>
                </Col>
              </Row>
            </div>
            <Button type="primary" onClick={handleSkillComparison} style={{ marginTop: '20px', width: '100%' }}>
              Analyze and Compare Skills
            </Button>
          </div>
        );
      case 4:
        return selectedRole && (
          <div>
            <Title level={3}>Skills Comparison Result : {selectedRole.role}</Title>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <Progress
                    percent={calculateMatchingPercentage(matchingSkills, similarSkills, roleSkills.length)}
                    status="active"
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                  <Text strong>
                    Overall Match: {Math.round(calculateMatchingPercentage(matchingSkills, similarSkills, roleSkills.length))}%
                  </Text>
                </Card>
              </Col>
              <Col span={12}>
              <Card title="Matching Skills & Knowledge" extra={<Tag color="blue">{similarSkills.length}</Tag>}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {similarSkills.map((skill, index) => (
                      <Tag key={index} color="blue">{skill}</Tag>
                    ))}
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Lacking Skills & Knowledges" extra={<Tag color="red">{lackingSkills.length}</Tag>}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {lackingSkills
                      .filter(skill => skill.split(' ').length > 1)
                      .map((skill, index) => (
                        <Tag key={index} color="red">{skill}</Tag>
                      ))}
                  </div>
                </Card>
          
              </Col>
              <Button 
                  type="primary"
                  onClick={handleGenerateRecommendation}
                  size="large"
                  icon={<RocketOutlined />}
                  style={{
                    height: '48px',
                    fontSize: '16px',
                    fontWeight: 500,
                    padding: '0 32px',
                    marginTop: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                >
                  View Our Recommendation
                </Button>
            </Row>
          
            {loading && <Spin tip="Generating recommendation..." />}
          </div>
        );
      default:
        return null;
    }
  };

  return (
<Layout style={{ minHeight: '100vh' }}>
  <Content style={{ padding: '20px' }}>
    {renderContent()}
  </Content>
  <Sider
    width={400}
    style={{
      background: '#fff',
      padding: '24px',
      overflowY: 'auto',
    }}
    collapsed={!siderVisible}
    collapsedWidth={0}
    trigger={null}
  >
    {selectedGoal && (
      <div className="sider-content">
        <div className="sider-header">

          <div className="goal-badge">
            <AimOutlined /> Objectives
          </div>
        </div>

        <div className="goal-section">
          <Title level={2} style={{ 
            fontSize: '22px', 
            marginBottom: '16px',
            color: '#1f1f1f'
          }}>
            {selectedGoal.goal_name}
          </Title>
          <Text style={{ 
            fontSize: '16px',
            color: '#595959',
            display: 'block',
            marginBottom: '24px'
          }}>
            {selectedGoal.description}
          </Text>
        </div>

        <Divider style={{ margin: '24px 0' }} />

        <div className="roles-section">
        <Title
  level={3}
  style={{
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '20px',
    color: '#1f1f1f',
  }}
>
  <UserOutlined /> Roles Involved{' '}
  <Tooltip title="Click on a role to explore its details and begin analyzing resumes.">
    <InfoCircleOutlined
      style={{
        fontSize: '18px',
        color: '#1890ff',
        cursor: 'pointer',
        marginLeft: '8px',
      }}
    />
  </Tooltip>
</Title>


          <div className="roles-container">
            {selectedGoal.roles && selectedGoal.roles.length > 0 ? (
              selectedGoal.roles.map((role) => {
                const IconComponent = iconMap[role.role] || UserOutlined;
                return (
                  <Card
                    key={role.id}
                    hoverable
                    onClick={() => handleRoleClick(role)}
                    className="role-card"
                    style={{
                      marginBottom: '12px',
                      background: 'linear-gradient(145deg, #1890ff, #096dd9)',
                      borderRadius: '12px',
                      border: 'none',
                      transition: 'all 0.3s ease'
                    }}
                    bodyStyle={{
                      padding: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div className="role-icon" style={{
                      background: 'rgba(255, 255, 255, 0.2)',
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '12px'
                    }}>
                      <IconComponent style={{ 
                        fontSize: '20px', 
                        color: 'white' 
                      }} />
                    </div>
                    <Title level={5} style={{ 
                      margin: 0, 
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: '500'
                    }}>
                      {role.role}
                    </Title>
                  </Card>
                );
              })
            ) : (
              <Text style={{
                textAlign: 'center',
                display: 'block',
                padding: '20px',
                background: '#f5f5f5',
                borderRadius: '8px',
                color: '#8c8c8c'
              }}>
                No roles available for this goal
              </Text>
            )}
          </div>
        </div>

        <style jsx>{`
          .sider-content {
            position: relative;
          }
          .sider-header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 24px;
          }
          .goal-badge {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            background: #f0f5ff;
            color: #1890ff;
            padding: 4px 12px;
            border-radius: 16px;
            font-size: 22px;
            margin-right: auto;
          }
          .role-card:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          }
          .roles-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        `}</style>
      </div>
    )}
  </Sider>

  <Modal
    title={null}
    visible={isModalVisible}
    onOk={handleModalClose}
    onCancel={handleModalClose}
    footer={[
      <Button key="ok" type="primary" onClick={handleModalClose}>
        OK
      </Button>,
    ]}
    width={800}
  >
    <RecommendationPageContent recommendation={recommendation} />
  </Modal>
</Layout>
  );
};

export default ResumeUpload;