import React, { useState, useEffect } from 'react';
import { Card, Row, Spin, Tag } from 'antd';

const postProcessResumeData = (data) => {
  const structuredData = {
    personalInfo: {
      name: null,
      email: null,
      phone: null,
      location: null,
    },
    employmentHistory: [],
    education: [],
    skills: [],
    personalityTraits: [],
    workNouns: [],
    sentiment: null,
  };

  if (data && data.resumeEntities) {
    const { skills, personalityTraits, workNouns, sentiment } = data.resumeEntities;

    // Filter out one-word skills
    const excludeOneWordSkills = (items) => {
      return items.filter((item) => item.trim().split(' ').length > 1);
    };

    structuredData.skills = excludeOneWordSkills(skills || []);
    structuredData.personalityTraits = personalityTraits || [];
    structuredData.workNouns = workNouns || [];
    structuredData.sentiment = sentiment || null;
  }

  return structuredData;
};


const ResumeParsing = ({ parsedData, roleSkills, aiAnalysis }) => {
  const [loading, setLoading] = useState(false);
  const [structuredData, setStructuredData] = useState({
    skills: [],
    personalityTraits: [],
    workNouns: [],
  });

  useEffect(() => {
    if (parsedData) {
      const processedData = postProcessResumeData(parsedData);
      setStructuredData(processedData);
    }
  }, [parsedData]);

  return (
    <div style={{ padding: '20px' }}>
      <Spin spinning={loading}>
        <Row gutter={16} style={{ marginTop: '20px' }}>
          <Card title="Extracted KSAs" style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {structuredData.skills.length > 0 ? (
                structuredData.skills.map((skill, index) => (
                  <Tag key={index} color="purple" style={{ marginBottom: '8px' }}>
                    {skill}
                  </Tag>
                ))
              ) : (
                <p>No skills found in AI analysis</p>
              )}
            </div>
          </Card>
        </Row>
      </Spin>
    </div>
  );
};

export default ResumeParsing;
