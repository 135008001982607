import React, { useState } from 'react';
import { Upload, Button, message, Typography, Card, Modal, Progress, Spin } from 'antd';
import { InboxOutlined, CheckCircleOutlined, TrophyOutlined, ThunderboltOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Dragger } = Upload;
const { Title, Text, Paragraph } = Typography;

const AnalyzeCandidate = () => {
  const [file, setFile] = useState(null);
  const [analyzedRoles, setAnalyzedRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analysisModalVisible, setAnalysisModalVisible] = useState(false);
  const [currentSkill, setCurrentSkill] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  const handleFileChange = (info) => {
    const { status, originFileObj } = info.file;
    if (status === 'done' || status !== 'uploading') {
      setFile(originFileObj);
      message.success(`${info.file.name} uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} upload failed.`);
    }
  };

  const handleAnalyzeResume = async () => {
    if (!file) {
      message.error('Please upload a resume for analysis.');
      return;
    }

    setLoading(true);
    setAnalysisModalVisible(true);
    const formData = new FormData();
    formData.append('resume', file);

    try {
      const response = await axios.post(`${API_URL}/analyze-resume`, formData, {
        onUploadProgress: (progressEvent) => {
          // You can use this to show upload progress if needed
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(percentCompleted);
        }
      });

      // Simulate skills matching process for visual feedback
      const allSkills = response.data.topRoles.reduce((acc, role) => {
        const skills = role.matchedSkills.map(skill => skill.split(' - ')[0]);
        return [...acc, ...skills];
      }, []);

      // Show each skill briefly before completing
      for (const skill of allSkills) {
        setCurrentSkill(skill);
        await new Promise(resolve => setTimeout(resolve, 800)); // Show each skill for 800ms
      }

      setAnalyzedRoles(response.data.topRoles);
      message.success('Resume analyzed successfully!');
    } catch (error) {
      console.error('Error analyzing resume:', error);
      message.error('Failed to analyze resume.');
    } finally {
      setLoading(false);
      setAnalysisModalVisible(false);
      setCurrentSkill('');
    }
  };

  const renderMatchScore = (score) => {
    const numericScore = parseFloat(score);
    const color = 
      numericScore >= 8 ? '#52c41a' :
      numericScore >= 6 ? '#1890ff' :
      numericScore >= 4 ? '#faad14' : '#ff4d4f';

    return (
      <Card
        bordered={false}
        style={{
          width: '180px',
          textAlign: 'center',
          backgroundColor: 'white'
        }}
      >
        <Progress
          type="circle"
          percent={numericScore * 10}
          format={() => (
            <div>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color }}>{score}</div>
              <div style={{ fontSize: '12px', color: '#666' }}>Match Score</div>
            </div>
          )}
          strokeColor={color}
        />
      </Card>
    );
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Title level={3}>
        <TrophyOutlined style={{ marginRight: '8px', color: '#faad14' }} />
        Analyze Your Resume
      </Title>
      
      <Card>
        <Dragger
          name="file"
          multiple={false}
          accept=".pdf,.doc,.docx"
          customRequest={({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
          onChange={handleFileChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          </p>
          <p className="ant-upload-text">Click or drag a resume file to upload</p>
          <p className="ant-upload-hint">
            Supports PDF, DOC, DOCX formats
          </p>
        </Dragger>

        <Button
          type="primary"
          onClick={handleAnalyzeResume}
          style={{ marginTop: '20px', width: '100%', height: '40px' }}
          loading={loading}
        >
          Analyze Resume
        </Button>
      </Card>

      {analyzedRoles.length > 0 && (
        <div style={{ marginTop: '24px' }}>
          <Title level={4}>
            <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
            Top Matching Roles
          </Title>
          
          {analyzedRoles.map((role, index) => (
            <Card 
              key={index} 
              style={{ 
                marginBottom: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div style={{ flex: 1, marginRight: '24px' }}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    {role.name}
                  </Title>
                  <Paragraph type="secondary" style={{ marginBottom: '24px' }}>
                    {role.description}
                  </Paragraph>
                </div>
                {renderMatchScore(role.matchScore)}
              </div>
            </Card>
          ))}
        </div>
      )}

      {/* Analysis Progress Modal */}
      <Modal
        title={
          <div style={{ textAlign: 'center' }}>
            <ThunderboltOutlined style={{ color: '#1890ff', fontSize: '24px' }} />
            <div style={{ marginTop: '8px' }}>Analyzing Resume</div>
          </div>
        }
        open={analysisModalVisible}
        footer={null}
        closable={false}
        centered
        width={400}
      >
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
          <div style={{ marginTop: '20px' }}>
            <Text style={{ fontSize: '16px' }}>
              {currentSkill ? (
                <>
                  Matching Skill: <Text strong>{currentSkill}</Text>
                </>
              ) : (
                'Processing resume...'
              )}
            </Text>
          </div>
          <div style={{ marginTop: '16px' }}>
            <Progress percent={100} status="active" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AnalyzeCandidate;