import React, { useState } from 'react';
import { Layout, Menu, Typography, List } from 'antd';
import {
  FileOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import AddRoleDescription from './AddRoleDescriptionPage';
import ViewRoleDescriptionsPage from './ViewRoleDescriptionPage';
import ManageGoals from './ManageGoals';

const { Content, Sider } = Layout;
const { Title, Paragraph } = Typography;

const AdminDashboard = ({ onLogout }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('1');
  const [collapsed, setCollapsed] = useState(false);

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return (
          <div>
            <Title level={3}>Welcome to the Admin Dashboard</Title>
            <Paragraph>
              This dashboard helps you manage your organization's goals and roles efficiently.
              Here’s how you can use the features available:
            </Paragraph>

            <List
              bordered
              dataSource={[
                {
                  title: 'Manage Office Goals',
                  description:
                    'Define and manage strategic goals for your organization. You can add, edit, or remove goals, and assign roles to support each goal.',
                },
                {
                  title: 'Add Role Descriptions',
                  description:
                    'Create comprehensive role descriptions, including responsibilities, knowledge, skills, and attributes required for each position.',
                },
                {
                  title: 'View Role Descriptions',
                  description:
                    'Review all existing role descriptions. Use this feature to ensure alignment with organizational goals.',
                },
              ]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={<Title level={4}>{item.title}</Title>}
                    description={<Paragraph>{item.description}</Paragraph>}
                  />
                </List.Item>
              )}
            />
          </div>
        );

      case '2':
        return (
          <div>
            <Title level={3}>Manage Office Goals</Title>
            <Paragraph>
              Add, edit, and organize goals to align with your office's strategic vision.
            </Paragraph>
            <ManageGoals />
          </div>
        );

      case '3':
        return (
          <div>
            <Title level={3}>Add Role Descriptions</Title>
            <Paragraph>
              Create detailed descriptions for roles, including required skills, attributes, and key responsibilities.
            </Paragraph>
            <AddRoleDescription />
          </div>
        );

      case '4':
        return (
          <div>
            <Title level={3}>View Role Descriptions</Title>
            <Paragraph>
              Review and manage all the role descriptions currently available in your system.
            </Paragraph>
            <ViewRoleDescriptionsPage />
          </div>
        );

      default:
        return <h2>Select a feature to get started.</h2>;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          style={{ background: '#4B2E83' }}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            mode="inline"
            onSelect={({ key }) => setSelectedMenuItem(key)}
            style={{ background: '#4B2E83' }}
          >
            <Menu.Item key="1" icon={<FileOutlined />}>
              Dashboard Overview
            </Menu.Item>
            <Menu.Item key="2" icon={<TeamOutlined />}>
              Office Goals
            </Menu.Item>
            <Menu.SubMenu key="sub1" icon={<FileOutlined />} title="Roles">
              <Menu.Item key="3" icon={<PlusCircleOutlined />}>
                Add Role Description
              </Menu.Item>
              <Menu.Item key="4" icon={<EyeOutlined />}>
                View Role Descriptions
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
            {renderContent()}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;