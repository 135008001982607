import React, { useRef } from 'react';
import { Typography, Image, Row, Col, Divider, Card, List, Space } from 'antd';
import { GlobalOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const RecommendationPageContent = ({ recommendation }) => {
  const pdfRef = useRef();

  if (!recommendation) {
    return <Text>No recommendation generated yet.</Text>;
  } 

  const { metadata, content } = recommendation;


  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>

      <div ref={pdfRef} style={{ clear: 'both' }}>
        {/* Header Section */}
        <div className="pdf-section" style={{ textAlign: 'center', marginBottom: '20px', pageBreakAfter: 'avoid' }}>
          <Image 
            width={100} 
            src="/images/cuhklogo.png" 
            preview={false} 
            alt="CUHK Logo"
            style={{ marginBottom: '15px' }}
          />
          <Title level={2} style={{ margin: '10px 0' }}>Professional Development Recommendation</Title>
        </div>

        {/* Letterhead Section */}
        <div className="pdf-section" style={{ marginBottom: '20px', pageBreakInside: 'avoid' }}>
          <Row gutter={24}>
            <Col span={12}>
              <Text strong style={{ display: 'block', marginBottom: '5px' }}>{metadata.institution.name}</Text>
              <Text strong style={{ display: 'block', marginBottom: '5px' }}>{metadata.institution.department}</Text>
              {metadata.institution.address.map((line, index) => (
                <Text key={index} style={{ display: 'block', marginBottom: '2px' }}>{line}</Text>
              ))}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Text>{new Date(metadata.date).toLocaleDateString()}</Text>
            </Col>
          </Row>
          <Divider style={{ margin: '15px 0' }} />
        </div>

        {/* Introduction Section */}
        <div className="pdf-section" style={{ marginBottom: '20px', pageBreakInside: 'avoid' }}>
          <Paragraph style={{ fontSize: '16px', lineHeight: '1.6' }}>
            {content.introduction}
          </Paragraph>
        </div>

        {/* Organizations Section */}
        <div className="pdf-section" style={{ marginBottom: '20px' }}>
          <Title level={3} style={{ marginBottom: '20px' }}>Recommended Professional Organizations</Title>
          <Row gutter={[24, 24]}>
            {content.organizationRecommendations.map((org, index) => (
              <Col key={index} xs={24} lg={12} style={{ pageBreakInside: 'avoid' }}>
                <Card title={org.name} bordered={true}>
              <p><strong>Relevant Skills:</strong></p>
              <List
                size="small"
                dataSource={org.relevantSkills}
                renderItem={skill => (
                  <List.Item>
                    <CheckCircleOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
                    {skill}
                  </List.Item>
                )}
              />
              <Divider />
              <p><strong>Recommended Programs:</strong></p>
              <ul>
                {org.courses.map((course, idx) => (
                  <li key={idx}>{course}</li>
                ))}
              </ul>
                  <a href={org.url} target="_blank" rel="noopener noreferrer">
                    <Space>
                      <GlobalOutlined />
                      <Text>Visit Website</Text>
                    </Space>
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        {/* Footer Section */}
        <div className="pdf-section" style={{ marginTop: '30px', pageBreakBefore: 'avoid' }}>
          <Card style={{ marginBottom: '20px' }}>
            <Paragraph style={{ 
              textAlign: 'center', 
              margin: '10px 0',
              fontSize: '16px', 
              color: '#555' 
            }}>
              Please contact CUHK Centre for Family Business for more information.
            </Paragraph>
          </Card>

          <Divider />

          <Row>
            <Col span={12}>
              <Text strong style={{ display: 'block' }}>Prof. Marshall Jen</Text>
              <Text strong style={{ display: 'block' }}>Chief Learning Officer</Text>
              <Text>CUHK Centre for Family Business</Text>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default RecommendationPageContent;